import React from 'react';
import './Team.css'; // Import the CSS file

// CEO, CTO, and CRO profiles
const team = [
  {
    name: 'Abdul Kareem',
    title: 'CEO & Co-founder',
    bio: `ELIYAS, Abdul Kareem (“Abdul”) is a Sri Lankan
entrepreneur with a family-owned establishment of
over 50 years. He was formerly the managing director of
an apparel manufacturing company out of Sri Lanka
called EAM Maliban Textiles. With a Bachelor of Arts in
Product Management from London, England, along with
an extensive history of experience, Abdul possesses the
technical skills and business acumen to be the leader of

ELIYAS, Abdul Kareem Haute-U AR Technologies Inc.`,
    image: '/testimonial/ceo.png' // Updated image path
  },
  {
    name: 'Sulochana Karthik',
    title: 'CTO & Co-founder',
    bio: `KARTHIK, Sulochana (“Sulochana”), the visionary CTO of a leading Canadian AI/AR fashion tech company, specializes in AI technologies and AR/VR/XR applications. With a decade of expertise in developing software applications for Fintech and a Master's degree in Engineering, Sulochana drives innovation and transforms ideas into successful businesses. Born and raised in India, Sulochana is a techpreneur committed to pushing the boundaries of technological advancement`,
    image: '/testimonial/cto.png' // Updated image path
  },
  {
    name: 'Ahmed Kabir',
    title: 'CRO & Co-founder',
    bio: `MALATHUSERI PARETHKUTTY, Ahmed Kabir,
(“Ahmed”), holds a Bachelor's Degree in Commerce and
Postgraduate Degree in Sociology from Kerala
University , India. His expertise lies in the
spanning product development, project
implementation, pre-sales consultancy, commercial
planning, as well as mentoring.`,
    image: '/testimonial/cro.png' // Updated image path
  }
];

const Team = () => {
  return (
    <div className="team-container">
      <h1 className="team-header">Meet Our Founders</h1>
      <div className="team-grid">
        {team.map((member, index) => (
          <div key={index} className="team-card">
            <img src={member.image} alt={member.name} className="team-image" />
            <h2 className="team-name">{member.name}</h2>
            <h3 className="team-title">{member.title}</h3>
            <p className="team-bio">{member.bio}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Team;

import React, { useState } from 'react';
import { FaEnvelope, FaPhoneAlt, FaAddressCard, FaLocationArrow } from 'react-icons/fa';
import './ContactUs.css';

function ContactUs() {
    const [state, setState] = useState({ name: '', email: '', message: '', subscribe: false });
    const [messageResult, setMessageResult] = useState('');

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setState({ ...state, [name]: type === 'checkbox' ? checked : value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const formData = {
            name: state.name,
            email: state.email,
            message: state.message,
            subscribe: state.subscribe
        };
        try {
            const response = await fetch('https://us-central1-hauteu-ar.cloudfunctions.net/addSubscriber', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                },
                body: JSON.stringify(formData)
            });

            const result = await response.json();
            if (response.ok) {
                setMessageResult('Thank you for your message! ' + (state.subscribe ? 'You are now subscribed to our newsletter.' : ''));
                setState({ name: '', email: '', message: '', subscribe: false }); 
            } else {
                setMessageResult(result.error || 'Failed to send message.');
            }
        } catch (error) {
            setMessageResult('Error submitting the form. Please try again later.');
        }
    };

    return (
        <div id="contact-us" className="text-white p-4" style={{ fontSize: '20px' }}>
            <h2 style={{ textAlign: 'left' }}>Contact Us</h2>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start', fontSize: '1.2rem' }}>
                <div style={{ flex: 1, marginRight: '20px' }}>
                    <div style={{ backgroundColor: 'rgba(255, 255, 255, 0.4)', padding: '20px', borderRadius: '10px', marginBottom: '20px' }}>
                        <h4><FaAddressCard /> Registered Office</h4>
                        <h5>Haute-U AR Technologies Inc</h5>
                        <h6><FaLocationArrow /> Canada Place Business Centre</h6>
                        <h6>404 999 Canada Place Vancouver BC V6C 3E2, Canada</h6>
                    </div>
                    <div style={{ backgroundColor: 'rgba(255, 255, 255, 0.4)', padding: '20px', borderRadius: '10px' }}>
                        <h4><FaAddressCard /> Business Address</h4>
                        <h5>Haute-U AR Technologies Inc</h5>
                        <h6><FaLocationArrow /> 49 Provincetown Rd</h6>
                        <h6>Scarborough, ON, M1C 5G7, Canada</h6>
                        <h6><FaPhoneAlt /> +1 (437) 258 7095</h6>
                        <h6><FaPhoneAlt /> +1 (437) 557 2069</h6>
                        <h6><FaEnvelope /> info@hauteuar.com</h6>
                    </div>
                </div>
                <div style={{ flex: 1 }}>
                    <div className="contact-form" style={{ backgroundColor: 'rgba(255, 255, 255, 0.1)', padding: '20px', borderRadius: '10px' }}>
                        <form onSubmit={handleSubmit}>
                            <div className="input-group">
                                <label htmlFor="name">Your Name:</label>
                                <input type="text" id="name" name="name" value={state.name} onChange={handleChange} required />
                            </div>
                            <div className="input-group">
                                <label htmlFor="email">Your Email:</label>
                                <input type="email" id="email" name="email" value={state.email} onChange={handleChange} autoComplete="email" required />
                            </div>
                            <div className="input-group">
                                <label htmlFor="message">Message:</label>
                                <textarea id="message" name="message" value={state.message} onChange={handleChange} required />
                            </div>
                            <div className="input-group">
                                <label htmlFor="subscribe">
                                    <input type="checkbox" id="subscribe" name="subscribe" checked={state.subscribe} onChange={handleChange} />
                                    Subscribe to our newsletter
                                </label>
                            </div>
                            <button type="submit">Send Message</button>
                            {messageResult && <p>{messageResult}</p>}
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ContactUs;
